<template>
  <div>
    <p v-if="isLoading">Loading...</p>
    <base-table
      v-if="!isLoading"
      :data="balance_data"
      :columns="table1.columns"
      thead-classes="text-primary"
    >
    </base-table>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
const tableColumns = [
  "Asset",
  "Total",
  "Available",
  "USDT",
];
const tableData = [];

export default {
  props: {
    balance_data: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  components: {
    BaseTable
  },
  data() {
    return {
      table1: {
        title: "Bots",
        columns: [...tableColumns],
        data: [...tableData]
      },
      isLoading: false
    };
  },
  methods: {
    loadTable() {
      this.isLoading = true;

      var token = this.$store.getters.token;

      
/*
      fetch(process.env.VUE_APP_BASE_URL + "account/balances?auth="+token)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          this.isLoading = false;

          if(data.code == 'danger')
          {
            alert(data.message);
            return;
          }

          const results = [];
          for (const id in data) {
            results.push({
              0: data[id].asset.toString(),
              1: data[id].totalBalance.toString(),
              2: data[id].availableBalance.toString(),
              3: data[id].usdt.toString(),
            });
          }

          this.table1.data = results;

        });

*/

    }
  },
  mounted() {
    //this.loadTable();
  }
};
</script>
<style>
</style>
