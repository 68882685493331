<template>
  <div>

    <div v-if="false" class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">{{$t('dashboard.totalBalance')}}</h5>
                <h2 class="card-title">{{$t('dashboard.currentBalance')}}: 345</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle"
                     :class="isRTL ? 'float-left' : 'float-right'"
                     data-toggle="buttons">
                  <label v-for="(option, index) in bigLineChartCategories"
                         :key="option"
                         class="btn btn-sm btn-primary btn-simple"
                         :class="{active: bigLineChart.activeIndex === index}"
                         :id="index">
                    <input type="radio"
                           @click="initBigChart(index)"
                           name="options" autocomplete="off"
                           :checked="bigLineChart.activeIndex === index">
                    {{option}}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="big-line-chart"
                        :chart-data="bigLineChart.chartData"
                        :gradient-colors="bigLineChart.gradientColors"
                        :gradient-stops="bigLineChart.gradientStops"
                        :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.currentBalance')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-coins text-info "></i> {{totalBalanceString}} USDT</h3>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                       ref="blueBarChart"
                       chart-id="blue-bar-chart"
                       :chart-data="blueBarChart.chartData"
                       :gradient-stops="blueBarChart.gradientStops"
                       :extra-options="blueBarChart.extraOptions">
            </bar-chart>
          </div>
        </card>
      </div>
      <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.btcGraph')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-bold text-primary "></i> {{currentBTCString}} USDT</h3>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="greenLineChart"
                        chart-id="green-line-chart"
                        :chart-data="greenLineChart.chartData"
                        :gradient-colors="greenLineChart.gradientColors"
                        :gradient-stops="greenLineChart.gradientStops"
                        :extra-options="greenLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
        <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.balanceGraph')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-chart-bar-32 text-success "></i> Weeks balance</h3>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="purpleLineChart"
                        chart-id="purple-line-chart"
                        :chart-data="purpleLineChart.chartData"
                        :gradient-colors="purpleLineChart.gradientColors"
                        :gradient-stops="purpleLineChart.gradientStops"
                        :extra-options="purpleLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
    <div class="col-lg-6 col-md-12">
        <card class="card" :header-classes="{'text-right': isRTL}">
          <h4 slot="header" class="card-title">{{$t('dashboard.balance')}}</h4>
          <div class="table-responsive">
            <balance-table :balance_data="balance_data"></balance-table>
          </div>
          <span>*Will not show low balances &lt;1 USDT</span>
        </card>
      </div>
      <div class="col-lg-6 col-md-12">
        <card class="card" :header-classes="{'text-right': isRTL}">
          <h4 v-if="false" slot="header" class="card-title">{{$t('dashboard.activeBots')}}</h4>
          <div class="table-responsive">
            <bots-table></bots-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  //import TaskList from './Dashboard/TaskList';
  //import UserTable from './Dashboard/UserTable';
  import BalanceTable from './Dashboard/BalanceTable';
  import BotsTable from './Dashboard/BotsTable';
  import config from '@/config';

  export default {
    components: {
      LineChart,
      BarChart,
      //TaskList,
      //UserTable,
      BalanceTable,
      BotsTable
    },
    data() {
      return {
        totalBalance: null,
        totalBalanceString: '-',
        currentBTCString: '-',
        balance_data: [],
        bigLineChart: {
          allData: [
            [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
            [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
            [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [{ }],
            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          },
          extraOptions: chartConfigs.purpleChartOptions,
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
          categories: []
        },
        purpleLineChart: {
          extraOptions: chartConfigs.purpleChartOptions,
          chartData: {
            labels: [],
            datasets: [{
              label: "",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.2, 0],
        },
        greenLineChart: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: [],
            datasets: [{
              label: "",
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            }]
          },
          gradientColors: ['rgba(66,134,121,0.15)', 'rgba(66,134,121,0.0)', 'rgba(66,134,121,0)'],
          gradientStops: [1, 0.4, 0],
        },
        blueBarChart: {
          extraOptions: chartConfigs.barChartOptions,
          chartData: {
            labels: [],
            datasets: [{
              label: "",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [],
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
        }
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      bigLineChartCategories() {
        return this.$t('dashboard.chartCategories');
      }
    },
    methods: {
      loadBalanceGraph() {
      this.isLoading = true;

      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "account/balances?auth="+token)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          this.isLoading = false;
          this.totalBalance=0;

         // console.log(this.blueBarChart.chartData.labels);

          if(data.code == 'danger')
          {
            return;
          }

          let new_labels = [];
          let new_data = [];
          let results_balance_data = [];
          for (const id in data) {
            
            results_balance_data.push({
              0: data[id].asset.toString(),
              1: data[id].totalBalance.toString(),
              2: data[id].availableBalance.toString(),
              3: data[id].usdt.replace(",", "").toString(),
            });

            new_labels.push(data[id].asset.toString());
            new_data.push(data[id].usdt.replace(",", "").toString());

            if(data[id].usdt != '-')
                this.totalBalance+=Number(data[id].usdt.replace(",", "").toString());
          }

          this.balance_data = results_balance_data;

          let chartData = {
            labels: new_labels,
            datasets: [{
              label: "",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: new_data,
            }]
          };

          if(this.$refs.blueBarChart)
            this.$refs.blueBarChart.updateGradients(chartData);
            
          this.blueBarChart.chartData = chartData;

          this.totalBalanceString = this.totalBalance.toFixed(2);

          //this.table1.data = results;
        });
      },
      loadWeekBalanceGraph(){
        this.isLoading = true;

        var token = this.$store.getters.token;

        fetch(process.env.VUE_APP_BASE_URL + "account/weeks_balance_history?auth="+token)
            .then(response => {
            if (response.ok) {
                return response.json();
            }
            })
            .then(data => {
            this.isLoading = false;

            let chartData = {
            labels: data.labels,
            datasets: [{
                label: "",
                fill: true,
                borderColor: config.colors.primary,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.primary,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.primary,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: data.values,
                }]
            };

            if(this.$refs.purpleLineChart)
                this.$refs.purpleLineChart.updateGradients(chartData);

            this.purpleLineChart.chartData = chartData;

            this.currentBTCString = data.values[9];
            });
      },
      loadBTCGraph() {
      this.isLoading = true;
      fetch(process.env.VUE_APP_BASE_URL + "graph/btc_1w")
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          this.isLoading = false;
          this.totalBalance=0;


          let chartData = {
            labels: data.labels,
            datasets: [{
              label: "",
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: data.values,
            }]
          };

          if(this.$refs.greenLineChart)
            this.$refs.greenLineChart.updateGradients(chartData);

          this.greenLineChart.chartData = chartData;

          this.currentBTCString = data.values[9];
        });
      },
      initBigChart(index) {
        let chartData = {
          datasets: [{
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.bigLineChart.allData[index]
          }],
          labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        }

        if(this.$refs.bigChart)
            this.$refs.bigChart.updateGradients(chartData);

        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }
    },
    mounted() {
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
      //this.initBigChart(0);


        this.loadBalanceGraph();


        // http://localhost/cryptolumen/public/graph/btc_1w

        this.loadBTCGraph();

        this.loadWeekBalanceGraph();
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    }
  };
</script>
<style>
</style>
