<template>
  <div>
    <p v-if="isLoading">Loading...</p>
    <h4 class="card-title">{{table1.title}}</h4>
    <base-table
      v-if="!isLoading"
      :data="table1.data"
      :columns="table1.columns"
      thead-classes="text-primary"
    >
    </base-table>

    <h4 class="card-title">{{table2.title}}</h4>
    <base-table
      v-if="!isLoading"
      :data="table2.data"
      :columns="table2.columns"
      thead-classes="text-primary"
    >
    </base-table>

    <h4 class="card-title">{{table3.title}}</h4>
    <base-table
      v-if="!isLoading"
      :data="table3.data"
      :columns="table3.columns"
      thead-classes="text-primary"
    >
    </base-table>

  </div>
</template>
<script>
import { BaseTable } from "@/components";
const tableColumns = [
  "#",
  "Pair symbol",
  "Status",
  "Trigger Price",
  "Drop %",
  "Sell Qty",
  "High price",
  "Sell price"
];

const tableBuyLowColumns = [
  "#",
  "Pair symbol",
  "Status",
  "Trigger Price",
  "Incr %",
  "Buy Qty",
  "Low price",
  "Buy price",
  ""
];

const tableGridColumns = [
  "#",
  "Pair symbol",
  "Status",
  "Trigger Price",
  "Current Step",
  "Date Start",
  //"Low limit",
  //"Upp limit",
  ""
];

const tableData = [];

const tableBuyLowData = [];

const tablGridData = [];

export default {
  components: {
    BaseTable
  },
  data() {
    return {
      table1: {
        title: "Bots Sell High",
        columns: [...tableColumns],
        data: [...tableData]
      },
      table2: {
        title: "Bots Buy Low",
        columns: [...tableBuyLowColumns],
        data: [...tableBuyLowData]
      },
      table3: {
        title: "Bots Grid",
        columns: [...tableGridColumns],
        data: [...tablGridData]
      },
      isLoading: false
    };
  },
  methods: {
    loadTable() {
      this.isLoading = true;
      
      var token = this.$store.getters.token;

      fetch(process.env.VUE_APP_BASE_URL + "bots/show_combined?auth="+token)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(data => {
          this.isLoading = false;

          var results = [];
          var id;

          for (id in data.bot_sell_high) {
            results.push({
              0: data.bot_sell_high[id].id.toString(),
              1: data.bot_sell_high[id].pair_symbol.toString(),
              2: data.bot_sell_high[id].status.toString(),
              3: data.bot_sell_high[id].trigger_price.toString(),
              4: data.bot_sell_high[id].drop_percent.toString(),
              5: data.bot_sell_high[id].sell_quantity.toString(),
              6: data.bot_sell_high[id].highest_price.toString(),
              7: data.bot_sell_high[id].current_sell_price.toString()
            });
          }

          this.table1.data = results;

          results = [];
          for (id in data.bot_buy_low) {
            results.push({
              0: data.bot_buy_low[id].id.toString(),
              1: data.bot_buy_low[id].pair_symbol.toString(),
              2: data.bot_buy_low[id].status.toString(),
              3: data.bot_buy_low[id].trigger_price.toString(),
              4: data.bot_buy_low[id].incr_percent.toString(),
              5: data.bot_buy_low[id].buy_quantity.toString(),
              6: data.bot_buy_low[id].lowest_price.toString(),
              7: data.bot_buy_low[id].current_buy_price.toString()
            });
          }

          this.table2.data = results;

          results = [];
          for (id in data.bot_grid) {
            results.push({
              0: data.bot_grid[id].id.toString(),
              1: data.bot_grid[id].pair_symbol.toString(),
              2: data.bot_grid[id].status.toString(),
              3: data.bot_grid[id].trigger_price.toString(),
              4: data.bot_grid[id].current_step_price.toString(),
              5: data.bot_grid[id].date_created.toString(),
              //6: data.bot_grid[id].lower_limit_price.toString(),
              //7: data.bot_grid[id].upper_limit_price.toString()
            });
          }

          this.table3.data = results;

        });
    }
  },
  mounted() {
    this.loadTable();
  }
};
</script>
<style>
</style>
